import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, updateJobData } from "../../../Redux/Slices/createJob";
import { getActiveTab } from "../helper";
import styles from "../CreateJob.module.css";
import tabStyles from "./Tabs.module.css";
import { FiArrowLeft } from "react-icons/fi";
import backgroundImage from "../../../Assests/Images/acceptGoods.png";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import SelectHelper from "../Modals/selectHelper";
import SelectDate from "../Modals/selectDate";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Toster from "../../../Utils/Toster";

export default function Fifth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobData = useSelector((state) => state.createJob);
  const { first, second, third, fourth } = useSelector(
    (state) => state.createJob.createJobData
  );
  const activeTab = useSelector((state) => state.createJob.activeTab);
  const [helper, setHelper] = useState({ show: false, val: "", id: "" });
  const [selectDate, setSelectDate] = useState({ show: false, val: undefined });
  const [selectTime, setSelectTime] = useState();
  const [showTimeError, setShowTimeError] = useState(false);
  const [isDayToday, setIsDayToday] = useState(false);
  const [diffDateTime, setDiffDateTime] = useState("");
  const [routetime, setRoutetime] = useState("");
  const [estimatedJobTime, setEstimatedJobTime] = useState({
    hour: 0,
    min: 0,
  });
  const [minEstimatedJobTime, setMinEstimatedJobTime] = useState("");
  const [isMinTimeError, setIsMinTimeError] = useState(false);
  const [totalDistance, setTotalDistance] = useState("");
  const [showTwoHoursError, setShowTwoHoursError] = useState(false);

  const isToday2 = (date_) => {
    // Get the current date
    const currentDate = dayjs();
    // Check if the date is today
    const isToday = date_.isSame(currentDate, "day");
    return isToday; // Compares only the date portion
  };

  useEffect(() => {
    // const date1String = "2024-03-18T12:00:00"; // Date 1
    const date1String = new Date(); // Date 1

    // const date2String = "2024-04-22T15:30:00";
    const date2String = selectDate.val; // Date 2
    // Date 2
    const selectedDate = selectDate.val
      ? moment(new Date(selectDate.val)).format("L")
      : "";
    const selectedTime = selectTime
      ? moment(new Date(selectTime)).format("LT")
      : "";
    const newSelectedDate = selectedDate + " " + selectedTime;
    const convertDate = new Date(newSelectedDate);
    const difference = getDateDifference(date1String, convertDate);
    setDiffDateTime(difference);
  }, [selectDate, selectTime]);

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isPacking, setIsPacking] = useState(false);
  // Function to calculate the difference between two dates
  function getDateDifference(dateString1, dateString2) {
    // Convert date strings to Date objects
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    // Calculate the difference in milliseconds
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());

    // Calculate days difference
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    // Calculate hours difference
    const hoursDiff = Math.floor(
      (timeDiff % (1000 * 3600 * 24)) / (1000 * 3600)
    );

    // Calculate minutes difference
    const minutesDiff = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));

    // Calculate seconds difference
    const secondsDiff = Math.floor((timeDiff % (1000 * 60)) / 1000);

    // Return an object containing the differences
    return {
      days: daysDiff,
      hours: hoursDiff,
      minutes: minutesDiff,
      seconds: secondsDiff,
    };
  }
  useEffect(() => {
    if (
      jobData.createJobData &&
      jobData.createJobData.fifth &&
      jobData.createJobData.fifth.isSubmit
    ) {
      setHelper((pre) => ({
        ...pre,
        val: jobData.createJobData.fifth.helper.val,
        id: jobData.createJobData.fifth.helper.id,
      }));
      setSelectDate((pre) => ({
        ...pre,
        val: dayjs(jobData.createJobData.fifth.date),
      }));
      setSelectTime(dayjs(jobData.createJobData.fifth.time));
      setIsDayToday(isToday2(dayjs(jobData.createJobData.fifth.date)));
      // setEstimatedJobTime(jobData.createJobData.fifth.estimatedJobTime);
      setIsPacking(jobData.createJobData.fifth.isPacking);
    } else {
      // setEstimatedJobTime(jobData.createJobData.fifth.estimatedJobTime);
    }
  }, []);

  const onBack = () => {
    dispatch(setActiveTab("fourth"));
  };

  const handleShowHelper = () => {
    setHelper((pre) => ({ ...pre, show: true }));
  };

  const goToNext = () => {
    setShowErrorMsg(true);
    const currentTime = dayjs();

    // Add 1 hour to the current time
    const oneHourLater = currentTime.add(1, "hour");

    // Assuming selectedTime is the time selected by the user
    const selectedTime = dayjs(selectTime); // Replace with the actual selected time

    // Check if the selected time is after 1 hour from the current hour
    const isAfterOneHour = selectedTime.isAfter(oneHourLater);
    if (isDayToday && isAfterOneHour == false) {
      Toster(t("TIME_AHEAD_HOUR"), "error");
      return;
    }
    const combineHoursAndMinutesToMinutes = (hours = 0, minutes = 0) => {
      return hours * 60 + minutes;
    };

    const hour = parseInt(estimatedJobTime.hour || 0); // Convert string hour to integer
    const minute = parseFloat(estimatedJobTime.min || 0); // Convert string minute to float

    const totalMinutes = combineHoursAndMinutesToMinutes(hour, minute);

    if (totalMinutes < 120) {
      setShowTwoHoursError(true);
      return;
    }

    if (totalMinutes <= minEstimatedJobTime) {
      setIsMinTimeError(true);
      return;
    } else {
      setIsMinTimeError(false);
    }

    if (helper.val && selectDate.val && selectTime) {
      dispatch(
        updateJobData({
          step: "fifth",
          data: {
            helper: { id: helper.id, val: helper.val },
            date: selectDate.val,
            time: selectTime,
            isPacking,
            estimatedTime: diffDateTime,
            estimatedJobTime,
            estimatedJobTimeMinutes: totalMinutes,
            totalDistance,
            isSubmit: true,
          },
        })
      );
      navigate("/job-summary");
    }
  };

  const handleTimeChange = (newTime, type) => {
    setSelectTime(newTime);
    const currentTime = dayjs();

    // Add 1 hour to the current time
    const oneHourLater = currentTime.add(1, "hour");

    // Assuming selectedTime is the time selected by the user
    const selectedTime = dayjs(newTime); // Replace with the actual selected time

    // Check if the selected time is after 1 hour from the current hour
    const isAfterOneHour = selectedTime.isAfter(oneHourLater);

    if (isDayToday && !isAfterOneHour) {
      setShowTimeError(true);
    } else {
      setShowTimeError(false);
    }
  };

  const calculateLiftTime = () => {
    const liftTime = Number(first.liftTime.option_value);
    if (first.hasSourceLift && first.hasDestinationLift) {
      return liftTime * 2;
    } else if (first.hasSourceLift || first.hasDestinationLift) {
      return liftTime;
    } else {
      return 0;
    }
  };

  const getPropertyTypeTime = () => {
    if (jobData.activeCategory == "mbk5ez") {
      const sourceTime = Number(first.propertyType.sourceProperty.time);
      const destinationTime = Number(
        first.propertyType.destinationProperty.time
      );
      return sourceTime + destinationTime;
    } else {
      return 0;
    }
  };

  async function calculateRoute() {
    const directionsService = new window.google.maps.DirectionsService();
    const stopss = first.stops.map((stop) => ({
      location: { lat: stop.lat, lng: stop.lng },
      // stopover: true, // Make sure to specify stopover as true for each stop
    }));
    const results = await directionsService.route({
      destination: {
        lat: first.destination_latitude,
        lng: first.destination_longitude,
      },
      origin: {
        lat: first.source_latitude,
        lng: first.source_longitude,
      },
      waypoints: stopss, // Add waypoints here
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    const distance = results.routes[0].legs.reduce(
      (total, leg) => total + leg.distance.value,
      0
    );

    // here we are getting time in seconds
    const duration = results.routes[0].legs.reduce(
      (total, leg) => total + leg.duration.value,
      0
    );
    const vehicleTime = fourth.selectedVehicle.time;
    setRoutetime(duration / 60);
    setMinEstimatedJobTime(duration / 60 + vehicleTime + getPropertyTypeTime());

    function convertMetersToMiles(meters) {
      const milesPerMeter = 0.000621371;
      return (meters * milesPerMeter).toFixed(2);
    }
    const miles = convertMetersToMiles(distance);
    setTotalDistance(miles);
  }

  useEffect(() => {
    if (first.isSubmit) {
      calculateRoute();
    }
  }, []);

  useEffect(() => {
    getEstimatedTime();
  }, [routetime]);

  const convertMinutesToHoursAndMinutes = (minutes) => {
    if (minEstimatedJobTime < 120 && !jobData.isBackToFifth) {
      return { hour: "2".toString(), min: "0".toString() };
    } else if (jobData.isBackToFifth) {
      return {
        hour: jobData.createJobData.fifth.estimatedJobTime.hour,
        min: jobData.createJobData.fifth.estimatedJobTime.min,
      };
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.round(minutes % 60);
      return { hour: hours.toString(), min: remainingMinutes.toString() };
    }
  };

  const getEstimatedTime = () => {
    const sourceToDestinationTime = 120;
    const sourceFloorTime = first.hasSourceLift ? 0 : first?.sourceFloor?.time;
    const destinationFloorTime = first.hasDestinationLift
      ? 0
      : first.destinationFloor.time;
    const vehicleTime = fourth.selectedVehicle.time;

    var mergedAllItems;

    if (third.isSubmit && second.isSubmit) {
      mergedAllItems = [...second.allItems, ...third?.customItems];
    } else if (third.isSubmit) {
      mergedAllItems = third?.customItems;
    } else if (second.isSubmit) {
      mergedAllItems = second.allItems;
    }
    const totalItemsTime = mergedAllItems.reduce((total, item) => {
      // Multiply time by quantity and add to total
      return total + item.time * item.quantity;
    }, 0);

    const totalTime =
      sourceFloorTime +
      Number(routetime) +
      destinationFloorTime +
      calculateLiftTime() +
      vehicleTime +
      totalItemsTime +
      getPropertyTypeTime();

    const estimatedTime = convertMinutesToHoursAndMinutes(totalTime);

    if (jobData.createJobData.fifth.isSubmit) {
      if (
        jobData.createJobData.fifth.estimatedJobTime.hour !==
          estimatedTime.hour ||
        jobData.createJobData.fifth.estimatedJobTime.min !== estimatedTime.min
      ) {
        setEstimatedJobTime(estimatedTime);
      } else {
        setEstimatedJobTime(jobData.createJobData.fifth.estimatedJobTime);
      }
    } else {
      setEstimatedJobTime(estimatedTime);
    }
    return totalTime;
  };

  const handleEstimatedtime = (value, type) => {
    if (!isNaN(value)) {
      if (value.includes(".")) {
        return;
      }
      if (type == "hour") {
        if (value >= 1000 || value.startsWith(0)) {
          return;
        }
        setEstimatedJobTime((pre) => ({ ...pre, hour: value }));
      } else {
        if (value >= 60 || value.startsWith(0)) {
          return;
        }
        setEstimatedJobTime((pre) => ({
          ...pre,
          min: value,
        }));
      }
    } else {
    }
  };

  const renderTimeError = () => {
    if (minEstimatedJobTime < 120) {
      return "Minimum job time should be 2 hours.";
    }
  };

  return (
    <>
      <div className="main">
        <div className="spaceTopManage">
          <div className={styles.topHead}>
            <div className="backarrow">
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div className="backarrow">
              <span className={styles.tabCount}>
                <strong>{getActiveTab(activeTab) - 1}</strong>
                <span>/4</span>
              </span>
            </div>
          </div>
          <div className={styles.topHeading}>
            <div className={styles.backarrow}>
              <FiArrowLeft onClick={() => onBack()} />
            </div>
            <div className={styles.tabCounting}>
              <strong>{getActiveTab(jobData.activeTab) - 1}</strong>
              <span>/4</span>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              width: "100%",
              padding: "20px 10px",
              backgroundPositionX: "center",
            }}
          >
            <div className={tabStyles.formBlock}>
              <Form>
                <div className={tabStyles.selectPeopleTitle}>
                  {t("SELECT_PEOPLE_HELP")}
                </div>
                <Row>
                  <Col>
                    <Form.Group className="formOrderSection fifthTab">
                      <span onClick={() => handleShowHelper()}>
                        <p>
                          {helper.val === "" ? (
                            <>{t("SELECT_HELPER")}</>
                          ) : (
                            helper.val
                          )}
                        </p>
                        <Icon
                          icon="bxs:up-arrow"
                          color="rgba(130, 130, 130, 0.8509803921568627)"
                          width="22"
                          height="22"
                          vFlip={true}
                        />
                      </span>
                      {!helper.val && showErrorMsg && (
                        <span className="validateError">
                          {t("SELECT_HELPER")}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <div className={tabStyles.selectPeopleTitle}>
                  {t("SELECT_DATE_TIME_JOB")}
                </div>
                <Row>
                  <Col>
                    <Form.Group className="formOrderSection fifthTab">
                      <span
                        onClick={() =>
                          setSelectDate((pre) => ({ ...pre, show: true }))
                        }
                      >
                        <p>
                          {selectDate.val === undefined ? (
                            <>{t("SELECT_DATE")}</>
                          ) : (
                            dayjs(selectDate.val).format("YYYY-MM-DD")
                          )}
                        </p>
                        <Icon
                          icon="uiw:date"
                          color="rgba(130, 130, 130)"
                          width="22"
                          height="22"
                        />
                      </span>
                      {!selectDate.val && showErrorMsg && (
                        <span className="validateError">
                          {t("SELECT_DATE")}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="formOrderSection fifthTab timePicker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <DesktopTimePicker
                            label={t("SELECT_TIME")}
                            value={selectTime}
                            disabled={!selectDate.val}
                            format={"HH:mm"}
                            ampm={false}
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "from")
                            }
                            className="customTimePicker"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <div className={tabStyles.formateText}>
                        {t("CLOCK_24_FORMATE")}
                      </div>
                      {!selectTime && showErrorMsg && (
                        <span className="validateError">
                          {t("Select time")}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <div className={tabStyles.timeEstimation}>
                  <div>
                    <Col>
                      {t("ESTIMATED_TIME_JOB")} {"-"}
                    </Col>
                  </div>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <div>
                      <Form.Group>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Form.Control
                            value={estimatedJobTime.hour}
                            onChange={(e) =>
                              handleEstimatedtime(e.target.value, "hour")
                            }
                            style={{
                              height: "40px",
                              width: "60px",
                              background: "#f9f9f9",
                            }}
                          />
                          <div>{"hour"}</div>
                        </div>
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Form.Control
                            value={estimatedJobTime.min}
                            onChange={(e) =>
                              handleEstimatedtime(e.target.value, "min")
                            }
                            style={{
                              height: "40px",
                              width: "60px",
                              background: "#f9f9f9",
                            }}
                          />
                          <div>{"min"}</div>
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                </div>
                {isMinTimeError && (
                  <p className="validateError">
                    Time should be greater than the minimum required job time,
                    which is{" "}
                    {convertMinutesToHoursAndMinutes(minEstimatedJobTime).hour}
                    {" hour and "}
                    {
                      convertMinutesToHoursAndMinutes(minEstimatedJobTime).min
                    }{" "}
                    minutes.
                  </p>
                )}
                {showTwoHoursError && (
                  <p className="validateError">{renderTimeError()}</p>
                )}
                {diffDateTime && selectDate.val && (
                  <div style={{ marginTop: "10px" }}>
                    {t("ESTIMATED_TIME_ARRIVAL")} -{" "}
                    <span className={tabStyles.estimatedTime}>
                      {diffDateTime.days
                        ? diffDateTime.days +
                          (diffDateTime.days > 1 ? " days" : " day")
                        : ""}{" "}
                      {diffDateTime.hours
                        ? diffDateTime.hours +
                          (diffDateTime.hours > 1 ? " hours" : " hour")
                        : ""}{" "}
                      {diffDateTime.minutes} {"min"}
                    </span>
                  </div>
                )}
                <div className={tabStyles.packingSection}>
                  <div>{t("WANT_PACKING")}</div>
                  <div>
                    <Form.Check
                      type="switch"
                      className="packing_switch"
                      id="packing-switch"
                      checked={isPacking}
                      onChange={(e) => {
                        setIsPacking(e.target.checked);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    className={tabStyles.fifthNextBtn}
                    onClick={() => goToNext()}
                  >
                    {t("NEXT")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <SelectHelper helper={helper} setHelper={setHelper} />
        <SelectDate
          selectDate={selectDate}
          setSelectDate={setSelectDate}
          selectTime={selectTime}
          setIsDayToday={setIsDayToday}
          setShowTimeError={setShowTimeError}
        />
      </div>
    </>
  );
}
