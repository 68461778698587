import React, { useEffect, useMemo, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import Styles from "../Driver/Driver.module.css";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../../Assests/Images/uil_search.png";
import { jobStatus, jobTrackingStatus } from "../../mockData";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Dropdown } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { STATUS_CODES } from "../../Utils/StatusCode";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { Rating } from "@mui/material";
import pdf from "../../Assests/Images/pdf.png";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { SlOptions } from "react-icons/sl";
import { IoMdEye } from "react-icons/io";
import { LIST_FOR, PAGE } from "../../Utils/Constant";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DriverStatusModal from "./DriverStatusModal";
import Loader from "../../Validation/LoadingSpinner";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { formatNumberOneDigit } from "../../Utils/FormateNumber";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DateRagePopup from "./DateRagePopup";
import VehicleStatusPopup from "./VehicleStatusPopup";
import DriverCategoryPopup from "./DriverCategoryPopup";
import { Space } from "antd";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../Utils/FormateMobileNumber";
import { calculateTotalAmountWithDiscount } from "../../Utils/CalculateAmountJob";
import { RangePicker, rangePresets } from "../../Utils/DateRange";

//-----function for driver details-------
const DriverDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const { id } = useParams();
  const [driverData, setDriverData] = useState("");
  const [vehicleData, setVehicleData] = useState("");
  const [documentData, setDocumentData] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [totalRecordsReview, setTotalRecordsReview] = useState();
  const [jobList, setJobList] = useState();
  const [search, setSearch] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState(null);
  const [filterByEndDate, setFilterByEndDate] = useState(null);
  const [filterByTrackJob, setFilterByTrackJob] = useState("");
  const [filterByJobStatus, setFilterByJobStatus] = useState("");
  const [filterByCategory, setFilterByCategory] = useState("");
  const [categegory, setCategegory] = useState("");
  const itemsPerPage = PAGE.PAGE_COUNT;
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [reviewList, setReviewList] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const [settings, setSettings] = useState([
    {
      id: "2",
      label: "No Help Required",
      type: "no_help_required",
      isChecked: false,
    },
    {
      id: "3",
      label: "With Driver Help",
      type: "driver_assistance",
      isChecked: false,
    },
    {
      id: "4",
      label: "Driver + 1 Porter",
      type: "driver_helper",
      isChecked: false,
    },
    {
      id: "5",
      label: "Driver + 2 Porters",
      type: "driver_2_helper",
      isChecked: false,
    },
    { id: "1", label: "Packing", type: "packing", isChecked: false },
  ]);

  useEffect(() => {
    if (driverData) {
      if (driverData.driverServiceStatus.length == 0) {
        return;
      } else {
        const serviceList = driverData.driverServiceStatus;

        // Create a map of service type to enable status for easy access
        const serviceMap = serviceList.reduce((map, item) => {
          map[item.service_type] = item.service_enable === 1;
          return map;
        }, {});

        // Update the setting state based on the serviceMap
        setSettings((prevState) =>
          prevState.map((item) => ({
            ...item,
            isChecked: serviceMap[item.type] || false,
          }))
        );
      }
    }
  }, [driverData]);

  // vehicle state
  const [vehicleId, setVehicleId] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  // driver categroy state
  const [driverServiceId, setDriverServiceId] = useState("");
  const [driverServiceStatus, setDriverServiceStatus] = useState("");

  // --Date Range show popoup
  const [dateRangeShow, SetDateRangeShow] = useState(false);

  const DateRangeHandleClose = () => SetDateRangeShow(false);
  const DateRangeHandleShow = () => SetDateRangeShow(true);

  // --Categroy show popoup
  const [furnitureShow, SetFurnitureShow] = useState(false);
  const FurnitureHandleClose = () => SetFurnitureShow(false);
  const FurnitureHandleShow = () => SetFurnitureShow(true);

  // --vehicle-status-show popoup
  const [vehicleShow, SetVehicleShow] = useState(false);
  const VehicleHandleClose = () => SetVehicleShow(false);
  const VehicleHandleShow = () => SetVehicleShow(true);

  // const [totalAmount, setTotalAmount] = useState(0);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  // function for set date
  const handleDateRangeChange = (dates) => {
    if (!dates) {
      getJobLists();
      return;
    }

    const [start, end] = dates;
    setFilterByStartDate(start);
    setFilterByEndDate(end);
  };

  // function for change category
  const ChangeCategory = (e) => {
    setFilterByCategory(e.target.value);
  };

  // function for change tracking status
  const trackingStatus = (e) => {
    setFilterByTrackJob(e.target.value);
  };

  // function for change job status
  const JobStatus = (e) => {
    setFilterByJobStatus(e.target.value);
  };

  // function for handle search
  const hadleSearch = (e) => {
    setSearch(e.target.value);
  };

  // function for get driver details api calling
  async function getDriverDetails() {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("driver_id", id);
    const details = await SwiftVanApi.driverDetail(requestData, userToken);
    setIsLoading(false);
    const msg = details && details.data && details.data.message;
    const ResponseCode =
      STATUS_MSG[details && details.data && details.data.code];
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setDriverData(details && details.data && details.data.driverData);
      setVehicleData(
        details &&
          details.data &&
          details.data.driverData &&
          details.data.driverData.user_vehicle
      );
      setDocumentData(
        details &&
          details.data &&
          details.data.driverData &&
          details.data.driverData.user_doc
      );
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    } else {
      if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.SERVER_VALIDATION
      ) {
        Toster(msg, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    }
  }

  useEffect(() => {
    getDriverDetails();
  }, [id]);

  // function for get category list
  useEffect(() => {
    async function getCategoryList(id = "", level = "") {
      const details = await SwiftVanApi.getCategoryList(userToken, id, level);
      if (details && details.code === STATUS_CODES.SUCCESS) {
        setCategegory(details && details.data);
      } else if (
        details &&
        details.data &&
        details.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      }
    }
    getCategoryList();
  }, []);

  // function for get job list
  async function getJobLists(reset = false) {
    let formattedStartDate = "";
    let formattedEndDate = "";

    if (!reset && filterByStartDate && filterByEndDate) {
      formattedStartDate = dayjs(filterByStartDate).format("YYYY-MM-DD");
      formattedEndDate = dayjs(filterByEndDate).format("YYYY-MM-DD");
    }

    const data = await SwiftVanApi.getJobList(
      userToken,
      pageNumber,
      search,
      LIST_FOR.DRIVER,
      id,
      "",
      formattedStartDate,
      formattedEndDate,
      reset == true ? "" : filterByCategory,
      reset == true ? "" : filterByJobStatus,
      reset == true ? "" : filterByTrackJob
    );
    if (data && data.code === STATUS_CODES.SUCCESS) {
      setJobList(data && data.data && data.data.jobList);
      setTotalRecords(data && data.data && data.data.jobListCount);
    } else if (
      data &&
      data.data &&
      data.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    getJobLists();
  }, [pageNumber, search]);

  function resetJobList() {
    setFilterByTrackJob("");
    setFilterByJobStatus("");
    setFilterByCategory("");
    getJobLists(true);
    setFilterByStartDate("");
    setFilterByEndDate("");
  }

  function applyFilterCall() {
    getJobLists();
  }

  //  Availability show time format
  useEffect(() => {
    const formattedData = driverData?.scheduleList?.map((item) => ({
      days: item.days,
      time_slots: JSON.parse(item.time_slots).map((slot) => ({
        start_time: moment(slot.start_time, "HH:mm:ss").format("hh:mm A"),
        end_time: moment(slot.end_time, "HH:mm:ss").format("hh:mm A"),
      })),
      is_available: item.is_available,
    }));
    setScheduleList(formattedData);
  }, [driverData]);

  // function for review list
  async function getDriverReviewLists() {
    setIsLoading(true);
    const data = await SwiftVanApi.getDriverReviewList(
      userToken,
      pageNumber,
      id
    );
    setIsLoading(false);
    if (data && data.code === STATUS_CODES.SUCCESS) {
      setReviewList(data && data.data && data.data.reviewList);
      setTotalRecordsReview(data && data.data && data.data.reviewCount);
    } else if (
      data &&
      data.data &&
      data.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/");
    }
  }

  useEffect(() => {
    getDriverReviewLists();
  }, [pageNumber]);

  // ------------**------table check box function---------**------------

  const [selectedRows, setSelectedRows] = useState([]);

  const handleCheckboxChange = (selectedItem, type) => {
    const getRows = jobList.filter(
      (val) => val.payment_release == "request_payment"
    );
    if (type == "single") {
      const isSelected = selectedRows.some((row) => row.id === selectedItem.id);

      if (isSelected) {
        setSelectedRows(
          selectedRows.filter((row) => row.id !== selectedItem.id)
        );
      } else {
        setSelectedRows([...selectedRows, selectedItem]);
      }
    }

    if (type == "multiple") {
      setSelectedRows(getRows);
    }
  };
  const handleAllSelect = (value) => {
    if (value) {
      handleCheckboxChange([], "multiple");
      setIsAllChecked(true);
    } else {
      setSelectedRows([]);
      setIsAllChecked(false);
    }
  };

  useEffect(() => {
    const ids = [];
    selectedRows.map((val) => {
      ids.push(val.id);
    });
    setSelectedIds(ids);

    const getRows = jobList?.filter(
      (val) => val.payment_release == "request_payment"
    );
    if (getRows?.length !== selectedRows.length) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [selectedRows, jobList]);

  const getCalculatedAmount = (jobList) => {
    let newValue = 0;
    if (jobList && jobList.length > 0) {
      for (let n = 0; n < jobList.length; n++) {
        const amount = calculateTotalAmountWithDiscount(
          jobList[n],
          "driver_earning"
        );
        if (amount !== undefined) {
          newValue += parseFloat(amount);
        }
      }
    }
    return newValue;
  };

  const totalReleaseAmount = useMemo(() => {
    return getCalculatedAmount(selectedRows);
  }, [selectedRows]);

  const totalDriverEarning = useMemo(() => {
    return getCalculatedAmount(jobList);
  }, [jobList]);

  // ------paymentRelease api---------
  const paymentReleaseStatus = () => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("job_id", selectedIds.join(","));
    let res = SwiftVanApi.paymentRelease(requestData, userToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        Toster(t("PAYMENT_RELEASE_SUCCESSFULLY"), "success");
        getJobLists();
        setIsLoading(false);
        setSelectedRows([]);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              <div className="driverDetailsMainSection">
                {/* ---userDetail--BackBtn---- */}
                <div className="userDetailsBackBtn">
                  <div className={Styles.backBtnMainDiv}>
                    <div className={Styles.backBtnInnarLeft}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/driver");
                        }}
                      >
                        <svg
                          width="58"
                          height="58"
                          viewBox="0 0 58 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d_1734_2262)">
                            <circle cx="29" cy="29" r="25" fill="#ECECEC" />
                          </g>
                          <path
                            d="M23.4114 29.958L31.578 38.1247L29.4999 40.1663L17.8333 28.4997L29.4999 16.833L31.578 18.8747L23.4114 27.0413H41.1666V29.958H23.4114Z"
                            fill="#202020"
                          />
                          <defs>
                            <filter
                              id="filter0_d_1734_2262"
                              x="0"
                              y="0"
                              width="58"
                              height="58"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1734_2262"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1734_2262"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </span>
                      <h1>{t("DRIVER_DETAILS")}</h1>
                    </div>
                  </div>
                </div>

                {/* ---userDetail--profile---- */}
                <div className="driverDetailsProfil">
                  <div className="userDetailsProfile">
                    <div className="userDetailsProfileInner">
                      <Row>
                        <div className="userProfileTop">
                          <Col>
                            <div className="ProfileTopInnerLeft">
                              <p>{t("DOCUMENT_VERIFICATION")}</p>
                              <h1>
                                {driverData &&
                                  driverData.user_doc_status == "pending" && (
                                    <div className="PendingClass">
                                      {t("PENDING")}
                                    </div>
                                  )}
                                {driverData &&
                                  driverData.user_doc_status == "rejected" && (
                                    <div className="InactiveClass">
                                      {t("REJECT")}
                                    </div>
                                  )}
                                {driverData &&
                                  driverData.user_doc_status == "approved" && (
                                    <div className="activeClass">
                                      {t("APPROVED")}
                                    </div>
                                  )}
                              </h1>
                            </div>
                          </Col>
                          <Col
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="driverDetailsImg">
                              <img
                                src={driverData && driverData.profile_image}
                                alt="profile-image"
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="ProfileTopInnerRight">
                              <Rating
                                name="read-only"
                                value={
                                  driverData &&
                                  driverData.rating &&
                                  driverData.rating.average_rating
                                }
                                precision={0.1}
                                readOnly
                              />
                              <h5>
                                {t("RATING")}{" "}
                                <span>
                                  (
                                  {driverData &&
                                    driverData.rating &&
                                    driverData.rating.average_rating &&
                                    formatNumberOneDigit(
                                      driverData &&
                                        driverData.rating &&
                                        Number(driverData.rating.average_rating)
                                    )}
                                  )
                                </span>
                              </h5>
                            </div>
                          </Col>
                        </div>
                      </Row>

                      <div className={Styles.userDetailsHeading}>
                        <h1>
                          {driverData && driverData.first_name}{" "}
                          {driverData && driverData.last_name}
                        </h1>
                        <p>{driverData && driverData.email}</p>
                        <h5>
                          {driverData &&
                          driverData.dial_code &&
                          !driverData.dial_code.includes("+")
                            ? `+${driverData && driverData.dial_code}`
                            : `${driverData && driverData.dial_code}`}{" "}
                          {formatPhoneNumber(
                            driverData && driverData.phone_number
                          )}
                        </h5>
                        <div></div>
                        <div className={Styles.businessLocation}>
                          <h5>
                            {t("UNIQUE_ID")} - {driverData.unique_id}
                          </h5>
                          <p>{driverData.company_name}</p>
                          <h5>{driverData.location}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ---Driver Packing & Helper Settings--- */}
                <div lassName="driverDetaildCategory">
                  <Row>
                    <Col lg={12}>
                      <Nav variant="pills">
                        <div className="driverDetaildTabBtn">
                          <h1>{t("Packing & Helper Settings")}</h1>
                          <div className="driverDetaildTabBtn_Right">
                            <Nav.Item>
                              {settings.map((val) => {
                                return (
                                  <div className={Styles.categegoryTabsInner}>
                                    <p>{val.label}</p>
                                    <div className={Styles.categegoryAppRejBtn}>
                                      <div
                                        className={Styles.vehicleTableAppBtn}
                                      >
                                        {val.isChecked ? (
                                          <div
                                            className={
                                              Styles.categegoryApprovedBtn
                                            }
                                          >
                                            {t("YES")}
                                          </div>
                                        ) : (
                                          <div
                                            className={
                                              Styles.categegoryRejectBtn
                                            }
                                          >
                                            {t("NO")}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Nav.Item>
                          </div>
                        </div>
                      </Nav>
                    </Col>
                  </Row>
                </div>

                {/* ---driverDetaildCategory--- */}
                <div className="driverDetaildCategory">
                  <Row>
                    <Col lg={12}>
                      <Nav variant="pills">
                        <div className="driverDetaildTabBtn">
                          <h1> {t("CATEGORY")}</h1>
                          <div className="driverDetaildTabBtn_Right">
                            <Nav.Item>
                              {driverData.user_services &&
                                driverData.user_services.length > 0 &&
                                driverData.user_services.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <div
                                        className={Styles.categegoryTabsInner}
                                      >
                                        <p>{item.name}</p>

                                        <div
                                          className={Styles.categegoryAppRejBtn}
                                        >
                                          {item && item.status == "pending" && (
                                            <div
                                              className={Styles.vehicleTableBtn}
                                            >
                                              <button
                                                onClick={() => {
                                                  FurnitureHandleShow(true);
                                                  setDriverServiceId(
                                                    item && item.id
                                                  );

                                                  setDriverServiceStatus(
                                                    "Pending"
                                                  );
                                                }}
                                              >
                                                {t("PENDING")}
                                              </button>
                                            </div>
                                          )}
                                          {item &&
                                            item.status == "rejected" && (
                                              <div
                                                className={
                                                  Styles.vehicleTableRejBtn
                                                }
                                              >
                                                <div
                                                  className={
                                                    Styles.categegoryRejectBtn
                                                  }
                                                >
                                                  {t("REJECT")}
                                                </div>
                                              </div>
                                            )}
                                          {item &&
                                            item.status == "approved" && (
                                              <div
                                                className={
                                                  Styles.vehicleTableAppBtn
                                                }
                                              >
                                                <div
                                                  className={
                                                    Styles.categegoryApprovedBtn
                                                  }
                                                >
                                                  {t("APPROVED")}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </Nav.Item>
                          </div>
                        </div>
                      </Nav>

                      <Col sm={12}>
                        <div className="userDetailsTabsMain">
                          <Tabs
                            defaultActiveKey="joblist"
                            id="justify-tab-example"
                            className="mb-3"
                            justify
                          >
                            {/* ---tab-joblist--- */}
                            <Tab eventKey="joblist" title={t("JOB_LIST")}>
                              {/*---search-section---*/}
                              <div className="topSearchSection">
                                <div className="topSearchSectionInner">
                                  <h1 className={Styles.jobListName}>
                                    {t("JOB_LIST")}
                                  </h1>
                                  <div className="topSearchMain">
                                    <img src={InputSearch} alt="" />
                                    <Form.Control
                                      type="search"
                                      placeholder={`${t("SEARCH_FOR_DRIVER")}`}
                                      value={search}
                                      onChange={(e) => hadleSearch(e)}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* ---userDetail---dropdown-dateall */}
                              <div className="UserDetailDropMenu">
                                <Row xs={1} sm={2} md={2} lg={4} xl={4}>
                                  <Col className="mb-3" xl={3}>
                                    <Form.Label>{t("DATE")}</Form.Label>
                                    <Form.Group
                                      className={Styles.selectDateRange}
                                    >
                                      <Space
                                        direction="vertical"
                                        size={12}
                                        style={{ width: "100%" }}
                                      >
                                        <RangePicker
                                          inputReadOnly={true}
                                          className="ant-picker-outlined"
                                          presets={rangePresets}
                                          value={[
                                            filterByStartDate,
                                            filterByEndDate,
                                          ]}
                                          onChange={handleDateRangeChange}
                                          format="DD/MM/YYYY"
                                        />
                                      </Space>
                                    </Form.Group>
                                  </Col>

                                  <Col className="mb-3">
                                    <Form.Label>{t("JOB_TRACK")}</Form.Label>
                                    <Form.Select
                                      className="inputDrop"
                                      aria-label="Default select example"
                                      type="date"
                                      value={filterByTrackJob}
                                      onChange={(value) =>
                                        trackingStatus(value)
                                      }
                                    >
                                      <option key={0} value="">
                                        {t("SELECT_STATUS")}{" "}
                                      </option>
                                      {jobTrackingStatus.map((item, index) => {
                                        return (
                                          <option
                                            key={index + 1}
                                            value={item.value}
                                          >
                                            {item.text}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Col>

                                  <Col className="mb-3">
                                    <Form.Label>
                                      {t("PAYMENT_STATUS")}
                                    </Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      className="inputDrop"
                                      value={filterByJobStatus}
                                      onChange={(value) => JobStatus(value)}
                                    >
                                      <option key={0} value="">
                                        {t("SELECT_PAYMENT_STATUS")}
                                      </option>
                                      {jobStatus.map((item, index) => {
                                        return (
                                          <option
                                            key={index + 1}
                                            value={item.value}
                                          >
                                            {item.text}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Col>

                                  <Col className="mb-3">
                                    <Form.Label>{t("CATEGORY")}</Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      className="inputDrop"
                                      value={filterByCategory}
                                      onChange={(value) =>
                                        ChangeCategory(value)
                                      }
                                    >
                                      <option key={0} value="">
                                        {t("SELECT_CATEGORY")}
                                      </option>
                                      {categegory &&
                                        categegory.length > 0 &&
                                        categegory.map((item, index) => {
                                          return (
                                            <option
                                              key={index + 1}
                                              value={item.id}
                                            >
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                    </Form.Select>
                                  </Col>

                                  <Col
                                    className="mb-3"
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                  >
                                    <div
                                      className="userDetailsBtn"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <button
                                        className="filterBtn"
                                        onClick={() => applyFilterCall()}
                                      >
                                        {t("APPLY_FILTER")}
                                      </button>
                                      <button
                                        className="resetBtn"
                                        onClick={() => resetJobList()}
                                      >
                                        {t("RESET")}
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              <div className="tableMainSection">
                                <div className="tableMainInner">
                                  <div className="tableBootomScroll">
                                    <div className="DashboardTableInner">
                                      <Table
                                        className="tabledashboard"
                                        striped
                                        responsive="lg"
                                      >
                                        <thead>
                                          <tr className="align-middle">
                                            {jobList &&
                                            jobList.some(
                                              (item) =>
                                                item.payment_release ===
                                                "request_payment"
                                            ) ? (
                                              <th
                                                className="tableThBg tablethleft leftsidepadding"
                                                style={{ minWidth: "40px" }}
                                              >
                                                <div
                                                  className={
                                                    Styles.tableCheckTd
                                                  }
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className={
                                                      Styles.tableCheckTdInputTh
                                                    }
                                                    checked={isAllChecked}
                                                    onChange={(e) =>
                                                      handleAllSelect(
                                                        e.target.checked
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </th>
                                            ) : (
                                              <th className="tableThBg tablethleft ">
                                                <div
                                                  className={
                                                    Styles.tableCheckTd
                                                  }
                                                ></div>
                                              </th>
                                            )}

                                            <th
                                              className="tableThBg  "
                                              style={{
                                                minWidth: "300px",
                                              }}
                                            >
                                              {t("SOURCE_LOCATION")}
                                            </th>
                                            <th
                                              className="tableThBg "
                                              style={{
                                                minWidth: "300px",
                                              }}
                                            >
                                              {t("DESTINATION_LOCATION")}
                                            </th>
                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "150px",
                                              }}
                                            >
                                              {t("CUSTOMER")}
                                            </th>

                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "100px",
                                              }}
                                            >
                                              {t("DATE")}
                                            </th>
                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "100px",
                                              }}
                                            >
                                              {t("PAYMENT_STATUS")}
                                            </th>
                                            <th
                                              className="tableThBg text-center"
                                              style={{
                                                minWidth: "80px",
                                              }}
                                            >
                                              {t("STOPS")}
                                            </th>
                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "120px",
                                              }}
                                            >
                                              {t("JOB_TRACK")}
                                            </th>
                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "120px",
                                              }}
                                            >
                                              {t("COMMISSION")}
                                            </th>
                                            <th
                                              className="tableThBg"
                                              style={{
                                                minWidth: "120px",
                                              }}
                                            >
                                              {t("AMOUNT")}
                                            </th>
                                            <th className="tableThBg tablethRight rightsidepadding">
                                              {t("ACTION")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {jobList && jobList.length > 0 ? (
                                            jobList.map((item, index) => {
                                              return (
                                                <tr
                                                  className="tableDashtd align-middle"
                                                  key={index}
                                                >
                                                  {item.payment_release ===
                                                  "request_payment" ? (
                                                    <td className="leftsidepadding">
                                                      <div
                                                        className={
                                                          Styles.tableCheckTd
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className={
                                                            Styles.tableCheckTdInput
                                                          }
                                                          checked={selectedRows.some(
                                                            (row) =>
                                                              row.id === item.id
                                                          )}
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              item,
                                                              "single"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <>
                                                      <td className="">
                                                        <div
                                                          className={
                                                            Styles.tableCheckTd
                                                          }
                                                        ></div>
                                                      </td>
                                                    </>
                                                  )}
                                                  <td className="dashboardTdSource ">
                                                    <div className="tableTextThreeLine">
                                                      {item &&
                                                      item.source_location ? (
                                                        <>
                                                          {capitalizeFirstLetter(
                                                            item &&
                                                              item.source_location
                                                          )}
                                                        </>
                                                      ) : (
                                                        "NA"
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className="dashboardTdSource">
                                                    <div className="tableTextThreeLine">
                                                      {item &&
                                                      item.destination_location ? (
                                                        <>
                                                          {capitalizeFirstLetter(
                                                            item &&
                                                              item.destination_location
                                                          )}
                                                        </>
                                                      ) : (
                                                        "NA"
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td className="textUpperLatter tableTextStartTop">
                                                    {item &&
                                                    item.customer_first_name ? (
                                                      <>
                                                        {item &&
                                                          item.customer_first_name}
                                                        &nbsp;
                                                        {item &&
                                                          item.customer_last_name}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>

                                                  <td
                                                    className={Styles.tableDate}
                                                    style={{
                                                      Color: "#000000",
                                                    }}
                                                  >
                                                    {item && item.date ? (
                                                      <>
                                                        {moment(
                                                          item && item.date
                                                        ).format("DD/MM/YYYY")}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className={`${Styles.tableStatus} textUpperLatter`}
                                                  >
                                                    {item && item.job_status ? (
                                                      <>
                                                        {item &&
                                                          item.job_status ==
                                                            "pending" && (
                                                            <div className="PendingClass">
                                                              {t("PENDING")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.job_status ==
                                                            "inprogress" && (
                                                            <div>
                                                              {t("INPROGRESS")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.job_status ==
                                                            "complete" && (
                                                            <div className="activeClass">
                                                              {t("COMPLETE")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.job_status ==
                                                            "cancel_by_admin" && (
                                                            <div className="InactiveClass">
                                                              {t(
                                                                "CANCEL_ADMIN"
                                                              )}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.job_status ==
                                                            "cancel_customer" && (
                                                            <div className="InactiveClass">
                                                              {t(
                                                                "CANCEL_CUSTOMER"
                                                              )}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.job_status ==
                                                            "cancel_driver" && (
                                                            <div className="InactiveClass">
                                                              {t(
                                                                "CANCEL_DRIVER"
                                                              )}
                                                            </div>
                                                          )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td className="tableTdStops text-center">
                                                    {item && item.stops}
                                                  </td>
                                                  <td
                                                    className={`${Styles.tableStatus} textUpperLatter`}
                                                  >
                                                    {item &&
                                                    item.track_status ? (
                                                      <>
                                                        {item &&
                                                          item.track_status ==
                                                            "on-the-way" && (
                                                            <div className="PendingClass">
                                                              {t("ON_THE_WAY")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.track_status ==
                                                            "pickup" && (
                                                            <div>
                                                              {t("PICKUP")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.track_status ==
                                                            "stop" && (
                                                            <div className="PendingClass">
                                                              {t("ON_THE_STOP")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.track_status ==
                                                            "delivered" && (
                                                            <div className="activeClass">
                                                              {t("DELIVERED")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.track_status ==
                                                            "start" && (
                                                            <div className="PendingClass">
                                                              {t("START")}
                                                            </div>
                                                          )}
                                                        {item &&
                                                          item.track_status ==
                                                            "reached" && (
                                                            <div className="activeClass">
                                                              {t("REACHED")}
                                                            </div>
                                                          )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </td>
                                                  <td
                                                    className={
                                                      Styles.tableAmount
                                                    }
                                                  >
                                                    {item &&
                                                    item.payment_summary ? (
                                                      <span>
                                                        <span
                                                          className={
                                                            Styles.currancySymbol
                                                          }
                                                        >
                                                          {item &&
                                                            item
                                                              .payment_summary[0]
                                                              .currency_symbol}
                                                        </span>

                                                        {parseFloat(
                                                          calculateTotalAmountWithDiscount(
                                                            item,
                                                            "commission"
                                                          )
                                                        ).toFixed(2)}
                                                      </span>
                                                    ) : (
                                                      "NA "
                                                    )}
                                                  </td>
                                                  <td
                                                    className={
                                                      Styles.tableAmount
                                                    }
                                                  >
                                                    {item &&
                                                    item.payment_summary[0]
                                                      .amount ? (
                                                      <span>
                                                        <span
                                                          className={
                                                            Styles.currancySymbol
                                                          }
                                                        >
                                                          {item &&
                                                            item
                                                              .payment_summary[0]
                                                              .currency_symbol}
                                                        </span>
                                                        {parseFloat(
                                                          item &&
                                                            item
                                                              .payment_summary[0]
                                                              .amount
                                                        ).toFixed(2)}
                                                      </span>
                                                    ) : (
                                                      "NA "
                                                    )}
                                                  </td>
                                                  <td className="text-center tableTextStartTop rightsidepadding">
                                                    <div className="TableDropdown">
                                                      <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                          <div className="optionListNew">
                                                            <SlOptions />
                                                          </div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="dropDownOnlyOneList">
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              navigate(
                                                                `/jobs/job-details/${item.id}`
                                                              );
                                                            }}
                                                          >
                                                            {t("VIEW_DROPDOWN")}
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="centeredCell"
                                              >
                                                <div className="dataRecord">
                                                  {t("NO_REC_FOUND")}
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                    <div className={Styles.releasePaymentBtn}>
                                      <span>
                                        <p>{t("TOTAL_EARNING")}&nbsp;:</p>£
                                        {totalDriverEarning.toFixed(2)}
                                      </span>

                                      {selectedRows.length !== 0 && (
                                        <div
                                          className={
                                            Styles.releasePaymentBtnCheckBox
                                          }
                                        >
                                          <span>
                                            <p>
                                              {t("AMOUNT_TO_BE_PAID")}
                                              &nbsp;:
                                            </p>
                                            £{totalReleaseAmount.toFixed(2)}
                                          </span>
                                          <button
                                            onClick={() => {
                                              paymentReleaseStatus();
                                            }}
                                          >
                                            {t("RELEASE_PAYMENT")}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/* pagination */}
                                  <div className="tablePaginationDash">
                                    {totalRecords > itemsPerPage && (
                                      <ReactPaginate
                                        previousLabel={<MdKeyboardArrowLeft />}
                                        nextLabel={<MdKeyboardArrowRight />}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageCount={Math.ceil(
                                          totalRecords / itemsPerPage
                                        )}
                                        breakLabel={
                                          <span className="page-link">...</span>
                                        }
                                        marginPagesDisplayed={0}
                                        pageRangeDisplayed={
                                          window.innerWidth > 768 ? 10 : 2
                                        }
                                        onPageChange={handlePageChange}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Tab>

                            {/* ---Vehicle Info--- */}
                            <Tab eventKey="vehicle" title={t("VEHICLE_INFO")}>
                              {/* table */}
                              <div className="tableMainSection">
                                <div className="tableMainInner">
                                  <div className="DriverTableInner">
                                    <Table striped responsive="lg">
                                      <thead>
                                        <tr className="align-middle ">
                                          <th
                                            className="tableThBg tablethleft"
                                            style={{
                                              minWidth: "200px",
                                              padding: "0px 20px",
                                            }}
                                          >
                                            {t("IMG")}
                                          </th>
                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "200px" }}
                                          >
                                            {t("PLATE_NUMBER")}
                                          </th>

                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "150px" }}
                                          >
                                            {t("CATEGORY")}
                                          </th>

                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "360px" }}
                                          >
                                            {t("DESCRIPTION")}
                                          </th>
                                          <th
                                            className="tableThBg"
                                            style={{ minWidth: "100px" }}
                                          >
                                            {t("STATUS")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {vehicleData &&
                                        vehicleData.length > 0 ? (
                                          vehicleData.map((vehicle, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="align-middle vehicleInfoheight"
                                              >
                                                <td
                                                  className="tableTdText"
                                                  style={{
                                                    width: "200px",
                                                    padding: "0px 20px",
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      width: "77px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                    src={
                                                      vehicle &&
                                                      vehicle.vehicle_image
                                                    }
                                                    alt=""
                                                  ></img>
                                                </td>
                                                <td
                                                  className="tableTdText"
                                                  style={{
                                                    width: "200px",
                                                  }}
                                                >
                                                  {vehicle &&
                                                  vehicle.licence_plate_number
                                                    ? vehicle.licence_plate_number
                                                    : "NA"}
                                                </td>
                                                <td
                                                  className="tableTdText"
                                                  style={{
                                                    width: "150px",
                                                  }}
                                                >
                                                  {vehicle && (
                                                    <>
                                                      {vehicle &&
                                                      vehicle.vehicle_type_name
                                                        ? vehicle.vehicle_type_name
                                                        : "NA"}
                                                    </>
                                                  )}
                                                </td>
                                                <td className="tableTdText textDescription">
                                                  <div className="">
                                                    {vehicle &&
                                                    vehicle.vehicle_description ? (
                                                      <>
                                                        {capitalizeFirstLetter(
                                                          vehicle &&
                                                            vehicle.vehicle_description
                                                        )}
                                                      </>
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </div>
                                                </td>
                                                <td className="tableTdText">
                                                  {vehicle &&
                                                    vehicle.status ==
                                                      "pending" && (
                                                      <div
                                                        className={
                                                          Styles.vehicleTableBtn
                                                        }
                                                      >
                                                        <button
                                                          onClick={() => {
                                                            VehicleHandleShow(
                                                              true
                                                            );
                                                            setVehicleId(
                                                              vehicle &&
                                                                vehicle.id
                                                            );

                                                            setVehicleStatus(
                                                              "Pending"
                                                            );
                                                          }}
                                                        >
                                                          {t("PENDING")}
                                                        </button>
                                                      </div>
                                                    )}
                                                  {vehicle &&
                                                    vehicle.status ==
                                                      "rejected" && (
                                                      <div
                                                        className={
                                                          Styles.vehicleTableRejBtn
                                                        }
                                                      >
                                                        <div className="InactiveClass">
                                                          {t("REJECT")}
                                                        </div>
                                                      </div>
                                                    )}
                                                  {vehicle &&
                                                    vehicle.status ==
                                                      "approved" && (
                                                      <div
                                                        className={
                                                          Styles.vehicleTableAppBtn
                                                        }
                                                      >
                                                        <div className="activeClass">
                                                          {t("APPROVED")}
                                                        </div>
                                                      </div>
                                                    )}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="centeredCell"
                                            >
                                              <div className="dataRecord">
                                                {t("NO_REC_FOUND")}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </Tab>

                            {/* ---Document--- */}
                            <Tab eventKey="Document" title={t("DOCUMENT")}>
                              <Container>
                                <div className="driverDetDocumentTab">
                                  <Row
                                    className={Styles.driverDetDocumentTabRow}
                                  >
                                    {documentData &&
                                      documentData.length > 0 &&
                                      documentData.map((document, index) => {
                                        return (
                                          <Col
                                            lg={6}
                                            md={6}
                                            className="mb-5"
                                            key={index}
                                          >
                                            <div className="driverDetDocumentTabInner">
                                              {document &&
                                                document.document_type ==
                                                  "driving_lincence" && (
                                                  <div
                                                    className={
                                                      Styles.documentExpirationDate
                                                    }
                                                  >
                                                    <h4>
                                                      {t("DRIVING_LICENCE")}
                                                    </h4>
                                                    <span>
                                                      {t("EXPIRATION_DATE")}
                                                      &nbsp;:&nbsp;
                                                      <p>
                                                        {document &&
                                                        document.expiry_date ? (
                                                          <>
                                                            {moment(
                                                              document &&
                                                                document.expiry_date
                                                            ).format(
                                                              "DD/MM/YY"
                                                            )}
                                                          </>
                                                        ) : (
                                                          "NA"
                                                        )}
                                                      </p>
                                                    </span>
                                                  </div>
                                                )}
                                              {document &&
                                                document.document_type ==
                                                  "goods_in_transit_insurance" && (
                                                  <div
                                                    className={
                                                      Styles.documentExpirationDate
                                                    }
                                                  >
                                                    <h4>
                                                      {t("GT_INSURANCE")}{" "}
                                                    </h4>
                                                    <span>
                                                      {t("EXPIRATION_DATE")}
                                                      &nbsp;:&nbsp;
                                                      <p>
                                                        {" "}
                                                        {document &&
                                                        document.expiry_date ? (
                                                          <>
                                                            {moment(
                                                              document &&
                                                                document.expiry_date
                                                            ).format(
                                                              "DD/MM/YY"
                                                            )}
                                                          </>
                                                        ) : (
                                                          "NA"
                                                        )}
                                                      </p>
                                                    </span>
                                                  </div>
                                                )}
                                              {document &&
                                                document.document_type ==
                                                  "hire_reward_insurance" && (
                                                  <div
                                                    className={
                                                      Styles.documentExpirationDate
                                                    }
                                                  >
                                                    <h4>
                                                      {t("HR_INSURANCE")}{" "}
                                                    </h4>
                                                    <span>
                                                      {t("EXPIRATION_DATE")}
                                                      &nbsp;:&nbsp;
                                                      <p>
                                                        {" "}
                                                        {document &&
                                                        document.expiry_date ? (
                                                          <>
                                                            {moment(
                                                              document &&
                                                                document.expiry_date
                                                            ).format(
                                                              "DD/MM/YY"
                                                            )}
                                                          </>
                                                        ) : (
                                                          "NA"
                                                        )}
                                                      </p>
                                                    </span>
                                                  </div>
                                                )}
                                              {document &&
                                                document.document_type ==
                                                  "public_liability_insurance" && (
                                                  <div
                                                    className={
                                                      Styles.documentExpirationDate
                                                    }
                                                  >
                                                    <h4>
                                                      {t("PL_INSURANCE")}{" "}
                                                    </h4>
                                                    <span>
                                                      {t("EXPIRATION_DATE")}
                                                      &nbsp;:&nbsp;
                                                      <p>
                                                        {" "}
                                                        {document &&
                                                        document.expiry_date ? (
                                                          <>
                                                            {moment(
                                                              document &&
                                                                document.expiry_date
                                                            ).format(
                                                              "DD/MM/YY"
                                                            )}
                                                          </>
                                                        ) : (
                                                          "NA"
                                                        )}
                                                      </p>
                                                    </span>
                                                  </div>
                                                )}

                                              <div className="driverDetCardImg">
                                                <div className="driverDetCardImgOverlay">
                                                  {document &&
                                                  document.document_image
                                                    .split(".")
                                                    .pop() == "pdf" ? (
                                                    <img
                                                      src={pdf}
                                                      alt="pdf-icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        document &&
                                                        document.document_image
                                                      }
                                                    />
                                                  )}
                                                  <span>
                                                    <IoMdEye
                                                      onClick={() =>
                                                        window.open(
                                                          document &&
                                                            document.document_image
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                </div>
                                              </div>

                                              {document &&
                                                document.status ==
                                                  "pending" && (
                                                  <div className="driverDetCardBtn">
                                                    <Row>
                                                      <Col>
                                                        <button
                                                          className="rejectBtn"
                                                          onClick={() => {
                                                            setShowAlertModal(
                                                              true
                                                            );
                                                            setDocumentId(
                                                              document &&
                                                                document.id
                                                            );
                                                            setDocumentStatus(
                                                              "rejected"
                                                            );
                                                            setTitle("reject");
                                                          }}
                                                        >
                                                          <span>
                                                            <svg
                                                              width="21"
                                                              height="21"
                                                              viewBox="0 0 21 21"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M6.74967 15.709L10.4997 11.959L14.2497 15.709L15.708 14.2507L11.958 10.5007L15.708 6.75065L14.2497 5.29232L10.4997 9.04232L6.74967 5.29232L5.29134 6.75065L9.04134 10.5007L5.29134 14.2507L6.74967 15.709ZM10.4997 20.9173C9.0587 20.9173 7.70454 20.6437 6.43718 20.0965C5.16981 19.5493 4.06738 18.8073 3.12988 17.8704C2.19238 16.9329 1.45037 15.8305 0.903841 14.5632C0.357314 13.2958 0.0837023 11.9416 0.0830078 10.5007C0.0830078 9.05968 0.356619 7.70551 0.903841 6.43815C1.45106 5.17079 2.19308 4.06836 3.12988 3.13086C4.06738 2.19336 5.16981 1.45135 6.43718 0.904818C7.70454 0.35829 9.0587 0.0846788 10.4997 0.0839844C11.9406 0.0839844 13.2948 0.357596 14.5622 0.904818C15.8295 1.45204 16.932 2.19405 17.8695 3.13086C18.807 4.06836 19.5493 5.17079 20.0965 6.43815C20.6438 7.70551 20.917 9.05968 20.9163 10.5007C20.9163 11.9416 20.6427 13.2958 20.0955 14.5632C19.5483 15.8305 18.8063 16.9329 17.8695 17.8704C16.932 18.8079 15.8295 19.5503 14.5622 20.0975C13.2948 20.6447 11.9406 20.918 10.4997 20.9173Z"
                                                                fill="#EC3B1E"
                                                              />
                                                            </svg>
                                                          </span>
                                                          <p>{t("REJECT")}</p>
                                                        </button>
                                                      </Col>
                                                      <Col>
                                                        <button
                                                          onClick={() => {
                                                            setShowAlertModal(
                                                              true
                                                            );
                                                            setDocumentId(
                                                              document &&
                                                                document.id
                                                            );
                                                            setDocumentStatus(
                                                              "approved"
                                                            );
                                                            setTitle("approve");
                                                          }}
                                                          className="approveBtn"
                                                        >
                                                          <span>
                                                            <svg
                                                              width="25"
                                                              height="25"
                                                              viewBox="0 0 25 25"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.4997 22.9173C13.8676 22.9173 15.2222 22.6479 16.486 22.1244C17.7498 21.6009 18.8981 20.8336 19.8654 19.8663C20.8326 18.8991 21.5999 17.7507 22.1234 16.4869C22.6469 15.2231 22.9163 13.8686 22.9163 12.5007C22.9163 11.1327 22.6469 9.77817 22.1234 8.51436C21.5999 7.25056 20.8326 6.10223 19.8654 5.13495C18.8981 4.16768 17.7498 3.40039 16.486 2.87691C15.2222 2.35342 13.8676 2.08398 12.4997 2.08398C9.737 2.08398 7.08748 3.18145 5.13398 5.13495C3.18047 7.08846 2.08301 9.73798 2.08301 12.5007C2.08301 15.2633 3.18047 17.9128 5.13398 19.8663C7.08748 21.8198 9.737 22.9173 12.4997 22.9173ZM12.2312 16.7136L18.0182 9.76917L16.2404 8.28769L11.2636 14.2588L8.68833 11.6824L7.05176 13.3189L10.524 16.7912L11.4198 17.687L12.2312 16.7136Z"
                                                                fill="#058321"
                                                              />
                                                            </svg>
                                                          </span>
                                                          <p>{t("APPROVE")}</p>
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )}
                                              {document &&
                                                document.status ==
                                                  "rejected" && (
                                                  <div
                                                    className="InactiveClass"
                                                    style={{
                                                      textAlign: "center",
                                                      fontSize: "20px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {t("REJECT")}
                                                  </div>
                                                )}
                                              {document &&
                                                document.status ==
                                                  "approved" && (
                                                  <div
                                                    className="activeClass"
                                                    style={{
                                                      textAlign: "center",
                                                      fontSize: "20px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {t("APPROVED")}
                                                  </div>
                                                )}
                                            </div>
                                          </Col>
                                        );
                                      })}
                                  </Row>

                                  <DriverStatusModal
                                    setShowAlertModal={setShowAlertModal}
                                    showAlertModal={showAlertModal}
                                    getDriverDetails={getDriverDetails}
                                    documentId={documentId}
                                    documentStatus={documentStatus}
                                    title={title}
                                  />
                                </div>
                              </Container>
                            </Tab>

                            {/* ---Availability--- */}
                            <Tab
                              eventKey="Availability"
                              title={t("AVAILABILITY_TAB")}
                            >
                              <div className="availability">
                                <div className="tableMainSection">
                                  <div className="tableMainInner">
                                    <div className="FloorSectionInner">
                                      <div
                                        className={
                                          Styles.MetaDatVanTableInner_floor
                                        }
                                      >
                                        <Table
                                          striped
                                          responsive="lg"
                                          style={{
                                            marginBottom: "0px",
                                          }}
                                        >
                                          <thead></thead>
                                          <tbody>
                                            {scheduleList &&
                                            scheduleList.length > 0 ? (
                                              scheduleList.map(
                                                (item, index) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      style={{
                                                        height: "45px",
                                                      }}
                                                      className="align-middle "
                                                    >
                                                      <td
                                                        style={{
                                                          paddingLeft: "15px",
                                                        }}
                                                        className={
                                                          Styles.floorTable
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            Styles.availabilityItem
                                                          }
                                                        >
                                                          {capitalizeFirstLetter(
                                                            item && item.days
                                                          )}
                                                          &nbsp;
                                                          :-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                          <span>
                                                            {item &&
                                                            item.is_available ===
                                                              1 ? (
                                                              <>
                                                                {
                                                                  item
                                                                    .time_slots[0]
                                                                    ?.start_time
                                                                }
                                                                &nbsp;
                                                                <p
                                                                  className={
                                                                    Styles.availabilityTO
                                                                  }
                                                                >
                                                                  {t("TO")}
                                                                </p>
                                                                &nbsp;
                                                                {
                                                                  item
                                                                    .time_slots[0]
                                                                    ?.end_time
                                                                }
                                                                {item
                                                                  .time_slots[1] && (
                                                                  <>
                                                                    {","}
                                                                    &nbsp;&nbsp;
                                                                    {
                                                                      item
                                                                        .time_slots[1]
                                                                        ?.start_time
                                                                    }
                                                                    &nbsp;
                                                                    <p
                                                                      className={
                                                                        Styles.availabilityTO
                                                                      }
                                                                    >
                                                                      {t("TO")}
                                                                    </p>
                                                                    &nbsp;
                                                                    {
                                                                      item
                                                                        .time_slots[1]
                                                                        ?.end_time
                                                                    }
                                                                  </>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <p
                                                                className={
                                                                  Styles.availabilityClosed
                                                                }
                                                              >
                                                                {t("CLOSED")}
                                                              </p>
                                                            )}
                                                          </span>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <td
                                                  colSpan="7"
                                                  className="centeredCell"
                                                >
                                                  <div className="availabilitydataRecord">
                                                    {t("NO_REC_FOUND")}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>

                            {/* ----Account Info----- */}
                            <Tab
                              eventKey="accountInfo"
                              title={t("Account Info")}
                              className="mt-5"
                            >
                              <div className={Styles.accountInfoSection}>
                                <div>
                                  {t("BANK_NAME")} :{" "}
                                  {driverData &&
                                    driverData.bankInfo &&
                                    driverData.bankInfo[0].bank_name}
                                </div>
                                <div>
                                  {t("ACCOUNT_NUMBER")} :{" "}
                                  {driverData &&
                                    driverData.bankInfo &&
                                    driverData.bankInfo[0].bank_account_no}
                                </div>
                                <div>
                                  {t("SORT_CODE")} :{" "}
                                  {driverData &&
                                    driverData.bankInfo &&
                                    driverData.bankInfo[0].sort_code}
                                </div>
                              </div>
                            </Tab>

                            {/* ----Review----- */}
                            <Tab
                              eventKey="Review"
                              title={t("REVIEW")}
                              className="mt-5"
                            >
                              <div className={Styles.reviewMainSection}>
                                {reviewList && reviewList.length > 0 ? (
                                  reviewList.map((item, index) => {
                                    return (
                                      <div
                                        className={Styles.reviewCard}
                                        key={index}
                                      >
                                        <div className={Styles.reviewCardTop}>
                                          <div className={Styles.reviewTopLeft}>
                                            <div
                                              className={Styles.TopLeftSection}
                                            >
                                              <img
                                                src={item.profile_image}
                                                alt="Image"
                                              />
                                            </div>
                                            <div
                                              className={`${Styles.TopRightSection} textUpperLatter `}
                                            >
                                              <h3>
                                                {item && item.first_name}
                                                &nbsp;
                                                {item && item.last_name}
                                              </h3>
                                              <h5>{item && item.email}</h5>
                                              <p>
                                                {item && item.created_at ? (
                                                  <>
                                                    {moment(
                                                      item && item.created_at
                                                    ).format("DD/MM/YYYY")}
                                                  </>
                                                ) : (
                                                  "NA"
                                                )}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="reviewTopRight">
                                            <div className="ProfileTopInnerRight">
                                              <div className="ProfileTopInnerRight">
                                                <Rating
                                                  name="read-only"
                                                  value={item && item.rating}
                                                  precision={0.5}
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <p
                                          className={
                                            Styles.reviewCardDescription
                                          }
                                        >
                                          {item && item.review}
                                        </p>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="dataRecord">
                                    {t("NO_REC_FOUND")}
                                  </div>
                                )}
                              </div>

                              {/* pagination */}
                              <div className="tablePaginationDash">
                                {totalRecordsReview > itemsPerPage && (
                                  <ReactPaginate
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageCount={Math.ceil(
                                      totalRecordsReview / itemsPerPage
                                    )}
                                    breakLabel={
                                      <span className="page-link">...</span>
                                    }
                                    marginPagesDisplayed={0}
                                    pageRangeDisplayed={
                                      window.innerWidth > 768 ? 10 : 2
                                    }
                                    onPageChange={handlePageChange}
                                  />
                                )}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <DateRagePopup
        DateRangeHandleClose={DateRangeHandleClose}
        dateRangeShow={dateRangeShow}
      />

      <DriverCategoryPopup
        FurnitureHandleClose={FurnitureHandleClose}
        furnitureShow={furnitureShow}
        driverServiceId={driverServiceId}
        driverServiceStatus={driverServiceStatus}
        SetFurnitureShow={SetFurnitureShow}
        getDriverDetails={getDriverDetails}
      />

      <VehicleStatusPopup
        VehicleHandleClose={VehicleHandleClose}
        vehicleShow={vehicleShow}
        vehicleId={vehicleId}
        vehicleStatus={vehicleStatus}
        SetVehicleShow={SetVehicleShow}
        getDriverDetails={getDriverDetails}
      />
    </>
  );
};

export default DriverDetails;
