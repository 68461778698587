import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
import Styles from "../Coupon/Coupon.module.css";
import Table from "react-bootstrap/Table";
import { Dropdown } from "react-bootstrap";
import { SlOptions } from "react-icons/sl";
import CouponPopup from "./CouponPopup";
import CouponDeletePopup from "./CouponDeletePopup";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../Utils/CapitalFirstLetter";
import { AiOutlinePercentage } from "react-icons/ai";
import Loader from "../../Validation/LoadingSpinner";
import ReactPaginate from "react-paginate";
import { PAGE } from "../../Utils/Constant";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import moment from "moment";

const Coupon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const authToken = useSelector((state) => state.user.userToken);
  const [isUpdate, setIsUpdate] = useState(false);
  const [couponUpdate, setCouponUpdate] = useState("");
  const [couponEdit, setCouponEdit] = useState("");
  const [id, setId] = useState("");

  const [totalRecords, setTotalRecords] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = PAGE.PAGE_COUNT;

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  // --coupon show popoup
  const [couponPopupShow, SetCouponPopupShow] = useState(false);
  const CouponPopupHandleClose = () => SetCouponPopupShow(false);
  const CouponPopupHandleShow = () => SetCouponPopupShow(true);

  // --coupon delete popoup
  const [couponDeletePopupShow, SetCouponDeletePopupShow] = useState(false);
  const CouponDeleteHandleClose = () => SetCouponDeletePopupShow(false);
  const CouponDeleteHandleShow = () => SetCouponDeletePopupShow(true);

  const EditCouponHandleShow = (data) => {
    setCouponEdit(data);
    CouponPopupHandleShow(true);
  };

  const CouponDataList = () => {
    setIsLoading(true);
    let res = SwiftVanApi.getCouponData(authToken, pageNumber);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      if (data && data.code === STATUS_CODES.SUCCESS) {
        setCouponUpdate(data.data.fetchCouponList);
        setTotalRecords(data && data.data && data.data.getCouponCount);
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // --coupon status change
  const changeStatus = (id) => {
    setIsLoading(true);
    let requestData = new FormData();
    requestData.append("coupon_id", id);
    let res = SwiftVanApi.changeCouponStatus(requestData, authToken);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      const SuccessCode = STATUS_MSG[data && data.code];
      if (data && data.code === STATUS_CODES.COUPON_STATUS_CHANGED_SUCCESS) {
        Toster(t(SuccessCode), "success");
        CouponDataList();
        setIsLoading(false);
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    CouponDataList();
  }, [pageNumber]);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="adminPageWrapper">
        <div className="adminPageInner">
          <div className="adminPageInner_sidebar">
            <Sidebar />
          </div>
          <div className="adminPageInner_rightside">
            <div className="headersection">
              <Header />
            </div>

            <div className="adminPageInner_heading">
              {/*---search-section---*/}

              <div className={Styles.couponHeading}>
                <h1>{t("PROMO_LIST")}</h1>
                <div className={Styles.vanTabAddVanButtion}>
                  <button
                    onClick={() => {
                      EditCouponHandleShow("");
                      setIsUpdate(false);
                    }}
                  >
                    {t("ADD_PROMO")}
                  </button>
                </div>
              </div>

              {/* table */}
              <div className="tableMainSection">
                <div className="tableMainInner">
                  <div className="tableBootomScroll">
                    <div className="UserTableInner">
                      <Table striped responsive="lg">
                        <thead>
                          <tr className="align-middle">
                            <th className="tableThBg tablethleft leftsidepadding ">
                              {t("Title")}
                            </th>
                            <th className="tableThBg">{t("Code")}</th>
                            <th className="tableThBg">{t("STATUS_ITEM")}</th>
                            <th className="tableThBg">{t("Discount")}</th>
                            <th className="tableThBg">{t("Valid Date")}</th>
                            <th className="tableThBg">{t("Show in list")}</th>

                            <th className="tableThBg tablethRight text-center ">
                              {t("ACTION")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {couponUpdate && couponUpdate.length > 0 ? (
                            couponUpdate.map((item, index) => {
                              return (
                                <tr key={index} className="align-middle">
                                  <td
                                    className="tableTdText  leftsidepadding"
                                    style={{ minWidth: "350px" }}
                                  >
                                    {item && item.coupon_desc ? (
                                      <>
                                        {capitalizeFirstLetter(
                                          item && item.coupon_desc
                                        )}
                                      </>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td
                                    className="tableTdText "
                                    style={{
                                      minWidth: "200px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {item && item.coupon_code
                                      ? item.coupon_code
                                      : "NA"}
                                  </td>
                                  <td
                                    className="tableTdText "
                                    style={{ minWidth: "120px" }}
                                  >
                                    {item.is_active === 1 ? (
                                      <div className="activeClass">
                                        {t("ACTIVE")}
                                      </div>
                                    ) : (
                                      <div className="InactiveClass">
                                        {t("INACTIVE")}
                                      </div>
                                    )}
                                  </td>

                                  <td
                                    className="tableTdText"
                                    style={{ minWidth: "120px" }}
                                  >
                                    {item && item.value ? (
                                      <span>
                                        {item && item.value}
                                        <AiOutlinePercentage />
                                      </span>
                                    ) : (
                                      "NA "
                                    )}
                                  </td>

                                  {!item?.coupon_category ? (
                                    <td
                                      className="tableTdText"
                                      style={{ minWidth: "150px" }}
                                    >
                                      {item && item.expire_date ? (
                                        <>
                                          {moment(
                                            item && item.expire_date,
                                            "DD-MM-YYYY"
                                          ).format("DD/MM/YYYY")}
                                        </>
                                      ) : (
                                        "NA"
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      className="tableTdText"
                                      style={{ minWidth: "150px" }}
                                    ></td>
                                  )}
                                  <td
                                    className="tableTdText "
                                    style={{ minWidth: "120px" }}
                                  >
                                    {item.is_visible === 1 ? (
                                      <div className="activeClass">
                                        {t("YES")}
                                      </div>
                                    ) : (
                                      <div className="InactiveClass">
                                        {t("NO")}
                                      </div>
                                    )}
                                  </td>

                                  {!item?.coupon_category ? (
                                    <td className="text-center">
                                      <div className="TableDropdown">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <div className="optionList">
                                              <SlOptions />
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropDownOnlyOneList">
                                            <Dropdown.Item
                                              onClick={() => {
                                                EditCouponHandleShow(item);
                                                setIsUpdate(true);
                                              }}
                                            >
                                              {t("EDIT_DROPDOWN")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() => {
                                                CouponDeleteHandleShow();
                                                setId(item.id);
                                              }}
                                            >
                                              {t("DELETE_DROPDOWN")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                changeStatus(item && item.id)
                                              }
                                            >
                                              {item.is_active === 1
                                                ? `${t("INACTIVE")}`
                                                : `${t("ACTIVE")}`}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  ) : (
                                    <td style={{ height: "50px" }}></td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="7" className="centeredCell">
                                <div className="dataRecord">
                                  {t("NO_REC_FOUND")}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                  {/* pagination */}
                  <div className="tablePaginationDash">
                    {totalRecords > itemsPerPage && (
                      <ReactPaginate
                        previousLabel={<MdKeyboardArrowLeft />}
                        nextLabel={<MdKeyboardArrowRight />}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        pageCount={Math.ceil(totalRecords / itemsPerPage)}
                        breakLabel={<span className="page-link">...</span>}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={window.innerWidth > 768 ? 10 : 3}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <CouponDeletePopup
        couponDeletePopupShow={couponDeletePopupShow}
        CouponDeleteHandleClose={CouponDeleteHandleClose}
        CouponDataList={CouponDataList}
        id={id}
      />

      <CouponPopup
        couponPopupShow={couponPopupShow}
        CouponPopupHandleClose={CouponPopupHandleClose}
        isUpdate={isUpdate}
        SetCouponPopupShow={SetCouponPopupShow}
        couponEdit={couponEdit}
        CouponDataList={CouponDataList}
      />
    </>
  );
};

export default Coupon;
