import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Styles from "./Coupon.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Validation } from "../../Validation/Validation";
import Loader from "../../Validation/LoadingSpinner";
import { AiOutlinePercentage } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCancel, MdOutlineDateRange } from "react-icons/md";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import SwiftVanApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { COUPON } from "../../Utils/Constant";
import moment from "moment";

const CouponPopup = ({
  couponPopupShow,
  CouponPopupHandleClose,
  isUpdate,
  SetCouponPopupShow,
  couponEdit,
  CouponDataList,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [startDate, setStartDate] = useState();
  const [formatedDate, setFormatedDate] = useState("");
  const [discountInput, setDiscountInput] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, formState, reset, clearErrors } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    isUpdate === true ? updateApi(data) : createApi(data);
  };

  // --date
  const handleDateChange = (date) => {
    const ddate = new Date(date);
    const year = ddate.getFullYear();
    const month = ddate.getMonth() + 1;
    const day = ddate.getDate();
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    const newDate = year + "-" + formattedMonth + "-" + formattedDay;
    setFormatedDate(newDate);
  };
  const minDate = new Date();

  useEffect(() => {
    if (isUpdate === false) {
      reset();
      clearErrors();
      setDiscountInput("");
      setStartDate("");
      setFormatedDate("");
    }
  }, [couponPopupShow]);

  useEffect(() => {
    reset();
    if (Object.keys(couponEdit).length > 0) {
      setValue("CouponDiscount", couponEdit ? couponEdit.value : "");
      setValue("CouponTitle", couponEdit ? couponEdit.coupon_desc : "");
      setValue("CouponCode", couponEdit ? couponEdit.coupon_code : "");
      setValue("CouponValidDate", couponEdit ? couponEdit.expire_date : "");
      setValue("isVisible", couponEdit?.is_visible ? true : false);
      setDiscountInput(couponEdit ? couponEdit.value : "");

      if (couponEdit && couponEdit.expire_date) {
        const date = moment(couponEdit.expire_date, "DD-MM-YYYY").toDate();
        setStartDate(date);
      } else {
        setStartDate("");
      }
    } else {
      reset();
      setValue("CouponValidDate", "");
      setDiscountInput("");
      setValue("isVisible", true);
    }
  }, [couponEdit, couponPopupShow]);

  const createApi = (data) => {
    const trimmedCouponTitle =
      data && data.CouponTitle && data.CouponTitle.trim();
    var PostData = new FormData();
    PostData.append("title", trimmedCouponTitle);
    PostData.append("valid_date", formatedDate);
    PostData.append("coupon_code", data ? data && data.CouponCode : "");
    PostData.append("action", COUPON.ADD_COUPON);
    PostData.append("value", Number(discountInput));
    PostData.append("is_visible", data.isVisible ? 1 : 0);

    setIsLoading(true);
    let res = SwiftVanApi.postCoupon(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      const SuccessCode = STATUS_MSG[data && data.code];
      if (data && data.code === STATUS_CODES.COUPON_ADD_SUCCESS) {
        Toster(t(SuccessCode), "success");
        setIsLoading(false);
        CouponDataList();
        SetCouponPopupShow(false);
        setStartDate();
        setFormatedDate();
        reset();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const updateApi = (data) => {
    const trimmedCouponTitle =
      data && data.CouponTitle && data.CouponTitle.trim();
    var PostData = new FormData();
    PostData.append("coupon_id", couponEdit.id ? couponEdit.id : "");
    PostData.append("title", trimmedCouponTitle);

    const ddate = new Date(startDate);
    const year = ddate.getFullYear();
    const month = ddate.getMonth() + 1;
    const day = ddate.getDate();
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    const newDate = year + "-" + formattedMonth + "-" + formattedDay;

    PostData.append("valid_date", newDate);
    PostData.append("coupon_code", data ? data && data.CouponCode : "");
    PostData.append("action", COUPON.EDIT_COUPON);
    PostData.append("value", Number(discountInput));
    PostData.append("is_visible", data.isVisible ? 1 : 0);

    setIsLoading(true);
    let res = SwiftVanApi.postCoupon(authToken, PostData);
    res.then((data) => {
      const msg = data && data.data && data.data.message;
      const ResponseCode = STATUS_MSG[data && data.data && data.data.code];
      const SuccessCode = STATUS_MSG[data && data.code];
      if (data && data.code === STATUS_CODES.COUPON_UPDATE_SUCCESS) {
        CouponDataList();
        Toster(t(SuccessCode), "success");
        setIsLoading(false);
        SetCouponPopupShow(false);
        reset();
        setStartDate();
        setFormatedDate();
      } else if (
        (data && data.code === STATUS_CODES.INVALID_TOKEN) ||
        (data && data.data && data.data.code === STATUS_CODES.INVALID_TOKEN)
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          data &&
          data.data &&
          data.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    reset();
  }, []);
  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Modal show={couponPopupShow} onHide={CouponPopupHandleClose}>
        <div className="CouponPopupMain">
          <div className="addVanPopupMain">
            <Modal.Header>
              {couponEdit && couponEdit.id ? (
                <Modal.Title>{t("UPDATE_PROMO")} </Modal.Title>
              ) : (
                <Modal.Title>{t("ADD_PROMO")} </Modal.Title>
              )}
              <MdCancel onClick={CouponPopupHandleClose} />
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("TITLE")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="CouponTitle"
                        {...register("CouponTitle", Validation.CouponTitle)}
                        onChange={(e) => {
                          setValue("CouponTitle", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_TITLE")}
                        autoComplete="off"
                      />
                      {formState.errors.CouponTitle && (
                        <p style={{ color: "red", mb: 1 }}>
                          {t(formState.errors.CouponTitle.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("CODE")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="CouponCode"
                        {...register("CouponCode", Validation.CouponCode)}
                        onChange={(e) => {
                          const upperCasedValue = e.target.value
                            .toUpperCase()
                            .trimStart()
                            .trimEnd();
                          setValue("CouponCode", upperCasedValue, {
                            shouldValidate: true,
                          });
                        }}
                        placeholder={t("ENTER_CODE")}
                        autoComplete="off"
                      />
                      {formState.errors.CouponCode && (
                        <p style={{ color: "red", mb: 1 }}>
                          {t(formState.errors.CouponCode.message)}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("DISCOUNT")}</Form.Label>
                      <div style={{ display: "flex", position: "relative" }}>
                        <div style={{ width: "100%" }}>
                          <Form.Control
                            type="text"
                            name="CouponDiscount"
                            value={discountInput}
                            {...register(
                              "CouponDiscount",
                              Validation.CouponDiscount
                            )}
                            onChange={(e) => {
                              setValue("CouponDiscount", e.target.value, {
                                shouldValidate: true,
                              });

                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                (/^\d+$/.test(inputValue) &&
                                  inputValue >= 1 &&
                                  inputValue <= 99)
                              ) {
                                setDiscountInput(inputValue);
                              }
                            }}
                            placeholder={t("ENTER_DISCOUNT")}
                            autoComplete="off"
                            min={1}
                            maxLength={2}
                          />
                          {formState.errors.CouponDiscount && (
                            <p style={{ color: "red", mb: 1 }}>
                              {t(formState.errors.CouponDiscount.message)}
                            </p>
                          )}
                        </div>

                        <div className={Styles.minTimeInput}>
                          <div className={Styles.CategoryDevider}></div>
                          <div className={Styles.SelectDuration}>
                            <div className={Styles.SelectDurationInner}>
                              <AiOutlinePercentage />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="popupInput">
                      <Form.Label>{t("VALID_DATE_TITLE")}</Form.Label>
                      <div className="dateMinDiv">
                        <DatePicker
                          selected={startDate}
                          {...register(
                            "CouponValidDate",
                            Validation.CouponValidDate
                          )}
                          onChange={(date) => {
                            handleDateChange(date);
                            setStartDate(date);
                            setValue("CouponValidDate", date, {
                              shouldValidate: true,
                            });
                          }}
                          minDate={minDate}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                          showMonthDropdown
                          changeMonth
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          placeholderText={t("SELECT_VALID_DATE_PLA")}
                        />
                        {formState.errors.CouponValidDate && (
                          <p style={{ color: "red", marginBottom: 1 }}>
                            {t(formState.errors.CouponValidDate.message)}
                          </p>
                        )}
                        <MdOutlineDateRange className={Styles.date} />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className={Styles.visibleContainer}>
                    <div>{t("Show in list")}</div>
                    <div>
                      <Form.Check
                        type="switch"
                        className="packing_switch"
                        id="packing-switch"
                        {...register("isVisible")}
                      />
                    </div>
                  </Col>
                </Row>

                <div className={Styles.AddVanBtn}>
                  {couponEdit && couponEdit.id ? (
                    <button type="submit">{t("UPDATE_PROMO")}</button>
                  ) : (
                    <button type="submit">{t("ADD_PROMO")}</button>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CouponPopup;
