import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import FooterLogo from "../../Assests/Images/footer_logo1.png";
import { useTranslation } from "react-i18next";
import styles from "../Footer/Footer.module.css";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <div className="footerSection">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand to="/dashborad">
            <img src={FooterLogo} alt="" />
          </Navbar.Brand>
          {/* <Nav>
            <Nav.Link href="#features">{t("PRIVACY_POLICY")}</Nav.Link>
            <Nav.Link href="#features">{t("TERMS_CONDITIONS")}</Nav.Link>
          </Nav> */}
          <p>
            {t("ALL_RIGHTS_RESERVED_SWIFTVAN")} <span>{currentYear}</span>
          </p>
        </Container>
      </Navbar>
      {/* <div className={styles.copyrightSection}>
        <Container>
          <p>
            {t("ALL_RIGHTS_RESERVED_SWIFTVAN")} <span>{currentYear}</span>
          </p>
        </Container>
      </div> */}
    </div>
  );
};

export default Footer;
