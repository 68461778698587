export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const Validation = {
  email: {
    required: "PLEASE_ENTER_EMAIL",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
    minLength: {
      value: 5,
      message: "EMAIL_MUST_BE_5_CHARACTER",
    },
    maxLength: {
      value: 50,
      message: "EMAIL_NOT_LESS_THAN_100_CHARACTER",
    },
    ...sanitizeHtmlTags(),
  },

  password: {
    required: "ENTER_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    ...sanitizeHtmlTags(),
  },

  confirmPassword: {
    required: "ENTER_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    ...sanitizeHtmlTags(),
  },

  PropertyTypes: {
    required: "PROPERTY_TYPES",
    minLength: {
      value: 2,
      message: "PROPERTY_TYPE_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "PROPERTY_TYPE_MUST_50_CH",
    },
    ...sanitizeHtmlTags(),
  },

  PropertyName: {
    minLength: {
      value: 2,
      message: "PROPERTY_NAME_2_CH",
    },
    maxLength: {
      value: 100,
      message: "PROPERTY_NAME_NOT_MORE_THAN_100_CH",
    },
    ...sanitizeHtmlTags(),
  },

  vehicleName: {
    required: "ENTER_VEHICLE_NANE",
    minLength: {
      value: 2,
      message: "VEHICALE_NAME_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "VEHICALE_NAME_MUST_50_CH",
    },
    ...sanitizeHtmlTags(),
  },

  vehicleDescription: {
    required: "ENTER_VEHICLE_DESCRIPTION",
    minLength: {
      value: 2,
      message: "VEHICLE_DESCRIPTION_MUST_2_CH",
    },
    maxLength: {
      value: 10000,
      message: "VEHICLE_DESCRIPTION_MUST_10000_CH",
    },
    ...sanitizeHtmlTags(),
  },

  minimumTime: {
    required: "ENTER_MINIMUM_TIME",
    maxLength: {
      value: 600,
      message: "MINIMUM_TIME_MUST_600_CH",
    },
    ...sanitizeHtmlTags(),
  },

  EnquiryCategory: {
    required: "ENTER_ENQUIRY_CATEGORY_NANE",
    minLength: {
      value: 2,
      message: "ENQUIRY_CATEGORY_NAME_MUST_2_CH",
    },
    maxLength: {
      value: 50,
      message: "ENQUIRY_CATEGORY_NAME_MUST_100_CH",
    },
    ...sanitizeHtmlTags(),
  },

  ColourName: {
    required: "ENTER_COLOUR_NAME",
    minLength: {
      value: 2,
      message: "COLOUR_NAME_MUST_2_CH",
    },
    maxLength: {
      value: 100,
      message: "COLOUR_NAME_MUST_100_CH",
    },
    ...sanitizeHtmlTags(),
  },

  ColourCode: {
    required: "ENTER_COLOUR_CODE",
    minLength: {
      value: 2,
      message: "COLOUR_CODE_MUST_2_CH",
    },
    maxLength: {
      value: 100,
      message: "COLOUR_CODE_MUST_100_CH",
    },
    ...sanitizeHtmlTags(),
  },

  CouponTitle: {
    required: "COUPON_TITLE",
    minLength: {
      value: 2,
      message: "COUPON_TITLE_MUST_2_CH",
    },
    maxLength: {
      value: 150,
      message: "COUPON_TITLE_MUST_150_CH",
    },
    ...sanitizeHtmlTags(),
  },

  CouponCode: {
    required: "ENTER_COUPON_CODE",
    minLength: {
      value: 3,
      message: "COUPON_CODE_MUST_3_CH",
    },
    maxLength: {
      value: 25,
      message: "COUPON_CODE_MUST_25_CH",
    },
    pattern: {
      value: /^[A-Za-z0-9~!@$%^*]*$/,
      message: "COUPON_CODE_SPECIAL_CHAR",
    },

    ...sanitizeHtmlTags(),
  },

  CouponDiscount: {
    required: "COUPON_DISCOUNT",
    minLength: {
      value: 1,
      message: "COUPON_DISCOUNT_MUST_1_CH",
    },
    maxLength: {
      value: 2,
      message: "COUPON_DISCOUNT_MUST_2_CH",
    },
    ...sanitizeHtmlTags(),
  },

  CouponValidDate: {
    required: "COUPON_VALID_DATE",
    ...sanitizeHtmlTags(),
  },

  // ---review
  ReviewTitle: {
    required: "REVIEW_TITLE",
    minLength: {
      value: 2,
      message: "REVIEW_TITLE_MUST_2_CH",
    },
    maxLength: {
      value: 100,
      message: "REVIEW_TITLE_MUST_100_CH",
    },
    ...sanitizeHtmlTags(),
  },

  ReviewDescription: {
    required: "ENTER_REVIEW_DESCRIPTION",
    minLength: {
      value: 3,
      message: "REVIEW_DESCRIPTION_MUST_3_CH",
    },
    maxLength: {
      value: 250,
      message: "REVIEW_DESCRIPTION_MUST_250_CH",
    },
    ...sanitizeHtmlTags(),
  },

  ReviewRating: {
    required: "ENTER_REVIEW_RATING",
    minLength: {
      value: 1,
      message: "REVIEW_RATING_MUST_1_CH",
    },
    maxLength: {
      value: 5,
      message: "REVIEW_RATING_MUST_5_CH",
    },
    ...sanitizeHtmlTags(),
  },

  DateRangeStartDate: {
    required: "DATE_RAGE_START_DATE",
    ...sanitizeHtmlTags(),
  },
  DateRangeEndDate: {
    required: "DATE_RAGE_END_DATE",
    ...sanitizeHtmlTags(),
  },
};
